import { render, staticRenderFns } from "./DetailsViewDriverAsignado.vue?vue&type=template&id=263b2b2a&"
import script from "./DetailsViewDriverAsignado.vue?vue&type=script&lang=js&"
export * from "./DetailsViewDriverAsignado.vue?vue&type=script&lang=js&"
import style0 from "./DetailsViewDriverAsignado.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports